/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BModal, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BSpinner, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required, forgotpasswordEmail } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import provider from '@/provider/provider'
import { SEND_LINK_RESET_PASSWORD } from '@/provider/url'
import { NoSpace } from '@/libs/helpers'
import moment from 'moment'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BModal,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      error: '',
      status: '',
      usernameEmail: '',
      emailverifikasi: '',
      showResendEmailVerification: false,
      dismissCountDown: 0,
      dismissSecs: 0,
      loading: false,
      custommessages: {
        required: 'Mohon masukan email.',
      },
      password: '',
      required,
      forgotpasswordEmail,

      NoSpace,
      countdownData: {
        date_time: moment(),
        time_now: moment().add(60, 'seconds'),
      },
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    resendTextStyle() {
      if (this.dismissSecs > 0) {
        return {
          color: '#828282',
        }
      }
      return {
        color: '#F95031',
      }
    },
  },
  methods: {
    handleClickResend() {
      if (this.dismissSecs === 0) {
        this.sendForgotPassord()
      }
    },
    countDownChanged() {
      if (this.dismissSecs > 0) {
        setTimeout(() => {
          this.dismissSecs -= 1
          this.countDownChanged()
        }, 1000)
      }
    },
    resendEmailVerification() {
      provider
        .insert({
          url: `${SEND_LINK_RESET_PASSWORD}?email=${this.usernameEmail}`,
        })
        .then(response => {
          if (response.code === 400) {
            this.error = response.message
            this.loading = false
            this.emailverifikasi = 'Belum ada akun yang menggunakan email ini.'
          } else {
            this.showResendEmailVerification = true
          }
        }).catch(() => {
          this.loading = false
        })
    },
    handleRedirectTodataforgot() {
      this.$router.push('login')
    },
    countNextExpiredCountDown() {
      return moment(this.countdownData.date_time).diff(moment(this.countdownData.time_now), 'seconds')
    },
    submit() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.sendForgotPassord()
        }
      })
    },
    async sendForgotPassord() {
      this.loading = true
      this.error = ''
      this.emailverifikasi = ''
      provider.insert({
        url: `${SEND_LINK_RESET_PASSWORD}?email=${this.usernameEmail}`,
      })
        .then(response => {
          if (response.code === 200 && response.data) {
            this.countdownData = {
              date_time: moment(response.data.date_time),
              time_now: moment(response.data.time_now),
            }

            const expiredCoundown = Math.abs(this.countdownData.date_time.diff(this.countdownData.time_now, 'seconds'))
            this.dismissSecs = expiredCoundown
            this.showResendEmailVerification = true
            this.loading = false
            this.$nextTick(() => this.countDownChanged())
          }
        }).catch(error => {
          if (error.response.data.code === 400 && !(error.response.data.data && error.response.data.data.date_time)) {
            this.error = error.response.data.message
            this.emailverifikasi = 'Belum ada akun yang menggunakan email ini.'
          }

          if (error.response.data.data && error.response.data.data.date_time && error.response.data.data.time_now) {
            this.countdownData = {
              date_time: moment(error.response.data.data.date_time),
              time_now: moment(error.response.data.data.time_now),
            }

            const expiredCoundown = Math.abs(this.countdownData.date_time.diff(this.countdownData.time_now, 'seconds'))

            this.dismissSecs = expiredCoundown
            this.showResendEmailVerification = true
            this.loading = false
            this.$nextTick(() => this.countDownChanged())
          }
          this.loading = false
        })
    },
  },
}
